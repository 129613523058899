import { Directive, ElementRef, HostBinding, HostListener, InputSignal, inject, input } from '@angular/core';
import { EbOverlayPanelComponent } from './overlay-panel/overlay-panel.component';

@Directive({
    selector: '[ebOverlay]',
})
export class EbOverlayDirective<T> {
    private readonly _elementRef = inject(ElementRef);

    overlayPanel: InputSignal<EbOverlayPanelComponent<T>> = input.required({ alias: 'ebOverlay' });
    overlayPanelData: InputSignal<T | null> = input<T | null>(null, { alias: 'ebOverlayData' });
    ebOverlayOrigin: InputSignal<ElementRef | null> = input<ElementRef | null>(null);
    ebOverlayGlobal: InputSignal<boolean> = input(false);

    @HostBinding('class.eb-overlay') protected get isOverlay(): boolean {
        return true;
    }

    @HostListener('click') protected show(): void {
        if (this.ebOverlayGlobal()) {
            this.overlayPanel().global();
        } else {
            this.overlayPanel().show(this.ebOverlayOrigin() || this._elementRef, this.overlayPanelData());
        }
    }
}
