import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AffixService {
    private readonly _fixed$: Subject<boolean> = new Subject<boolean>();
    private _sectionIntersecting = false;
    private _buttonIntersecting = true;

    public get fixed$(): Observable<boolean> {
        return this._fixed$.asObservable();
    }

    public get buttonIntersecting(): boolean {
        return this._buttonIntersecting;
    }

    public set buttonIntersecting(buttonIntersecting: boolean) {
        if (buttonIntersecting !== this._buttonIntersecting) {
            this._buttonIntersecting = buttonIntersecting;
            this._next();
        }
    }

    public get sectionIntersecting(): boolean {
        return this._sectionIntersecting;
    }

    public set sectionIntersecting(sectionIntersecting: boolean) {
        if (sectionIntersecting !== this._sectionIntersecting) {
            this._sectionIntersecting = sectionIntersecting;
            this._next();
        }
    }

    private _next(): void {
        const isFixed = !this._buttonIntersecting && !this._sectionIntersecting;
        this._fixed$.next(isFixed);
    }
}
