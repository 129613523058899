import { CommonModule, DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    InputSignal,
    PLATFORM_ID,
    WritableSignal,
    inject,
    input,
    signal,
} from '@angular/core';
import { IconComponent } from 'libs/ui-icons/src/lib/icon/icon.component';
import { Subject } from 'rxjs';

export declare type EbDrawerPlacement = 'left' | 'right' | 'top' | 'bottom';

@Component({
    selector: 'eb-custom-drawer',
    templateUrl: './custom-drawer.component.html',
    styleUrls: ['./custom-drawer.component.less'],
    host: { ngSkipHydration: 'true' },
    standalone: true,
    imports: [IconComponent, CommonModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EbCustomDrawerComponent {
    private readonly _document = inject(DOCUMENT);
    private readonly _platformId = inject(PLATFORM_ID);

    isGallery: InputSignal<boolean> = input(false);
    isFullHeight: InputSignal<boolean> = input(true);
    isTitleEventCalendarItem: InputSignal<boolean> = input(false);

    isVisible: WritableSignal<boolean> = signal(false);
    visibilityChange: Subject<boolean> = new Subject<boolean>();
    protected closing: WritableSignal<boolean> = signal(false);

    public toggleShow(): void {
        this.isVisible() ? this.close() : this.show();
    }

    public show(): void {
        setTimeout(() => {
            this.isVisible.set(true);
            if (this.visibilityChange.observed) {
                this.visibilityChange.next(true);
            }
        });
        if (isPlatformBrowser(this._platformId)) {
            this._document.documentElement.classList.add('overflow-hidden');
        }
    }

    public close(leaveWindowScrollblock?: boolean): void {
        if (this.isVisible()) {
            this.closing.set(true);

            setTimeout(() => {
                this.isVisible.set(false);
                if (this.visibilityChange.observed) {
                    this.visibilityChange.next(false);
                }

                this.closing.set(false);
                if (isPlatformBrowser(this._platformId) && !leaveWindowScrollblock) {
                    this._document.documentElement.classList.remove('overflow-hidden');
                }
            }, 250);
        }
    }

    public closeWithNoAnimation(): void {
        if (isPlatformBrowser(this._platformId)) {
            this._document.documentElement.classList.remove('overflow-hidden');
        }
        this.isVisible.set(false);
    }
}
