<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="origin()!"
  [cdkConnectedOverlayPositions]="overlayPositions"
  [cdkConnectedOverlayOpen]="isOpen()"
  (detach)="close()"
  (overlayOutsideClick)="close()"
>
  <div class="eb-overlay-panel" [ngClass]="overlayStyle()">
    @if(contentTemplate()) {
      <ng-container [ngTemplateOutlet]="contentTemplate()" [ngTemplateOutletContext]="{ $implicit: data }" />
    }
  </div>
</ng-template>
