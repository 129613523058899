import { CdkOverlayOrigin, ConnectionPositionPair, Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  Component,
  ContentChild,
  DestroyRef,
  ElementRef,
  Input,
  InputSignal,
  TemplateRef,
  ViewContainerRef,
  WritableSignal,
  contentChild,
  inject,
  input,
  signal
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationStart, Router } from '@angular/router';
import { ShopQueueService } from 'apps/portal/src/app/services/shop-queue.service';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'eb-overlay-panel',
    templateUrl: './overlay-panel.component.html',
    styleUrls: ['./overlay-panel.component.less'],
})
export class EbOverlayPanelComponent<T> {
    private readonly _destroyRef = inject(DestroyRef);
    private readonly _overlay = inject(Overlay);
    private readonly _viewContainerRef = inject(ViewContainerRef);
    private readonly _router = inject(Router);
    private readonly _shopQueueService = inject(ShopQueueService);

    isOpen: WritableSignal<boolean> = signal(false);
    origin: WritableSignal<CdkOverlayOrigin | undefined> = signal<CdkOverlayOrigin | undefined>(undefined);

    overlayStyle: InputSignal<any> = input(null);
    isCalendarPanel: InputSignal<boolean> = input(false);

    readonly contentTemplate = contentChild.required(TemplateRef, { read: TemplateRef });

    data: WritableSignal<T | null> = signal<T | null>(null);

    readonly overlayPositions = [] || [
        new ConnectionPositionPair({ originX: 'end', originY: 'top' }, { overlayX: 'start', overlayY: 'top' }),
        new ConnectionPositionPair({ originX: 'start', originY: 'top' }, { overlayX: 'end', overlayY: 'top' }),
    ];

    private _globalOverlayRef?: OverlayRef;

    constructor() {
        this._router.events
            .pipe(
                filter((event) => event instanceof NavigationStart),
                takeUntilDestroyed(),
            )
            .subscribe(() => {
                this._globalOverlayRef?.dispose();
            });
    }

    public show(el: ElementRef, data: T | null): void {
        this.data.set(data);
        this.origin.set(new CdkOverlayOrigin(el));
        this.isOpen.set(true);
    }

    public global(): void {
        this._globalOverlayRef = this._createOverlay();

        this._globalOverlayRef
            .backdropClick()
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe((_) => this._globalOverlayRef?.dispose());

        const portal = new TemplatePortal(this.contentTemplate(), this._viewContainerRef, null);

        this._globalOverlayRef.attach(portal);
    }

    public close(): void {
        if (this.isCalendarPanel() && Array.isArray(this.data) && this.data.length > 1) {
            this._shopQueueService.clearQueue();
        }
        this.isOpen.set(false);
        if (this._globalOverlayRef) {
            this._globalOverlayRef.dispose();
        }
    }

    private _createOverlay(): OverlayRef {
        const overlayConfig = this._getOverlayConfig();
        return this._overlay.create(overlayConfig);
    }

    private _getOverlayConfig(): OverlayConfig {
        const positionStrategy = this._overlay.position().global().centerHorizontally().top();

        const overlayConfig = new OverlayConfig({
            hasBackdrop: true,
            scrollStrategy: this._overlay.scrollStrategies.block(),
            positionStrategy,
            disposeOnNavigation: true,
        });

        return overlayConfig;
    }
}
