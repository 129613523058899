import { isPlatformBrowser } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    InputSignal,
    Output,
    PLATFORM_ID,
    TemplateRef,
    WritableSignal,
    inject,
    input,
    signal,
    viewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationStart, Router } from '@angular/router';
import { ShopQueueService } from 'apps/portal/src/app/services/shop-queue.service';
import { DRAWER_ANIMATE_DURATION, NzDrawerComponent } from 'ng-zorro-antd/drawer';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

export declare type EbDrawerPlacement = 'left' | 'right' | 'top' | 'bottom';

@Component({
    selector: 'eb-drawer',
    templateUrl: './drawer.component.html',
    styleUrls: ['./drawer.component.less'],
    host: { ngSkipHydration: 'true' },
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EbDrawerComponent {
    private readonly _platformId = inject(PLATFORM_ID);
    private readonly _router = inject(Router);
    private readonly _shopQueueService = inject(ShopQueueService);

    private _isVisible: WritableSignal<boolean> = signal(false);
    readonly visibilityChange: Subject<boolean> = new Subject();

    @Input() public set isVisible(value: boolean) {
        const normValue = value || false;

        if (normValue !== this._isVisible()) {
            this._isVisible.set(normValue);
            this.visibilityChange.next(this._isVisible());
            if (this._isVisible()) {
                this.nzDrawerComponent().open();
            } else {
                this.nzDrawerComponent().close();
            }
        }
    }

    @Output() readonly isVisibleChange = new EventEmitter<boolean>();

    closable: InputSignal<boolean> = input(false);
    mask: InputSignal<boolean> = input(true);
    placement: InputSignal<EbDrawerPlacement> = input<EbDrawerPlacement>('top');
    footer: InputSignal<string | TemplateRef<any>> = input<string | TemplateRef<any>>('');
    header: InputSignal<string | TemplateRef<any>> = input<string | TemplateRef<any>>('');
    width: InputSignal<string | number> = input<string | number>('');
    height: InputSignal<string | number> = input<string | number>('auto');
    bodyStyle: InputSignal<any> = input({ padding: '0' });
    maskStyle: InputSignal<any> = input();
    wrapClassName: InputSignal<string> = input('');

    readonly nzDrawerComponent = viewChild.required(NzDrawerComponent);

    constructor() {
        this._router.events
            .pipe(
                filter((e) => e instanceof NavigationStart && this.isVisible),
                takeUntilDestroyed(),
            )
            .subscribe((e) => {
                this.close();
                // przejście na nową strone trwa krócej niż zamknięcie drawera
                // dlatego opóźniamy scrollowanie na górę strony o animację zamknięcia
                // przydatne w przypadku wejścia na strone na której już byliśmy
                if (isPlatformBrowser(this._platformId)) {
                    setTimeout(() => {
                        window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: 'instant' as ScrollBehavior,
                        });
                    }, this._getAnimationDuration() + 1);
                }
            });
    }

    public show(): void {
        if (!this.isVisible) {
            this.isVisible = true;
        }
    }

    public close(): void {
        if (this.isVisible) {
            this._shopQueueService.clearQueue();
            this.isVisible = false;
        }
    }

    private _getAnimationDuration(): number {
        return this.nzDrawerComponent().nzNoAnimation ? 0 : DRAWER_ANIMATE_DURATION;
    }
}
